<template>
	<!-- 会员企业-->
	<div class="titleTap-box">
		<location :locationArr="locationArr"/>
	    <div class="enterpriseBox">
	        <div class="enterpriseLogo" @click="jumpUrl(item.qyWebUrl,'hyqy')"
	             v-for="(item,index) in hyqyList.records" :key="index">
	            <div class="LogoBox">
	                <img :src="item.qyImage">
	            </div>
	            <p :title="item.qyName">{{item.qyName}}</p>
	        </div>
	    </div>
	
	    <el-pagination v-if="hyqyList.total>0" style="text-align: right; margin: 60px 0;"
	                   background
	                   prev-text="上一页"
	                   next-text="下一页"
	                   layout="prev, pager, next"
	                   :current-page="page"
	                   @current-change="handleCurrentChange"
	                   :page-size="30"
	                   :total="hyqyList.total">
	    </el-pagination>
	</div>
</template>

<script>
	 import location from '@/components/location.vue'
	export default{
		name:'hyqy',
		data() {
			return{
				typeId: '',
				page: 1, // 默认页数
				locationArr: null,   //面包屑的数据
				hyqyList:[]
			}
		},
		components:{
			location
		},
		mounted() {
		    this.typeId = this.$route.query.typeId;
		
		    this.refreshData();
		      // 会员企业数据
		    // this.getData();
		    this.typeList = [
		        {typeName: '会员企业', typeId: 'hyqy'},
		       ];
		},
		created() {
			this.typeId = this.$route.query.typeId;
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						this.$router.push({
						    path: "/mobile/hyqy/list",
							query:{
								typeId:this.$route.query.typeId
							}
						});
			}
		},
		watch: {
		    $route: 'refreshData'
		},
		methods:{
			initLocation() {
			    const locationArr = [];
			    let typeId = this.typeId;
			    let title = "";
			    if (typeId == "hyqy") {
			        title = "会员企业";
			    }
			    locationArr.push({"title": title, "url": "/hyqy?typeId=" + typeId})
			    this.locationArr = locationArr;
			},
			refreshData() {
			    let typeId = this.$route.query.typeId;
			    if ((typeId != null && typeId != '' && typeof (typeId) != 'undefined')) {
			        this.typeId = typeId
			    } 
			    // this.page = 1;
				// 面包屑导航
			    this.initLocation()
                 this.gethyqyListFn()
			},
			// 会员企业跳转
			jumpUrl(url, dataType) {
			    if (url && url != "") {
			        if (dataType == 'hyqy') {
			            window.open(url, '_blank');
			        } else {
			            window.location.href = url
			        }
			    }
			},
			// 获取会员列表的数据
			gethyqyListFn(){
				this.$http.hyzqHyqyList({page: this.page}).then(res => {
				    if (res.code == 200) {
				        this.hyqyList = res.data
						// console.log(this.hyqyList)
				    }
				})
			},
			// 分页点击事件
			handleCurrentChange(page) {
			    this.page = page
				this.gethyqyListFn()
			 
			},
			
		}
	}
</script>

<style scoped="scoped">
	@import '~@/assets/css/member.css';
	.member {
	       width: 1200px;
	       margin: 0 auto;
	   }
	
	   .titleTap-box {
	       margin-bottom: 60px;
	   }
	.titleTap {
	       text-align: left;
	   }
	
	   .titleTap > h1 {
	       font-size: 28px;
	    /*   margin-top: 56px; */
	       color: #005bac;
	   }
	
	   .titleTap > h1 > span {
	       color: #000000;
	   }
	
	   .titleTap > div {
	       width: 80px;
	       height: 4px;
	       background-color: #005bac;
	       margin: 16px 0 21px 0;
	   }
	
	   .care {
	       margin-bottom: 60px;
	   }
	
	   .memberBoxOne {
	       margin-top: 45px;
	   }
		/* 暂无数据 */
		.nodata{
			min-height: 500px;
			text-align: center;
			line-height: 500px;
			font-size: 16px;
			color: #000000;
		}
		.enterpriseLogo p{
			overflow:hidden; 
			text-overflow:ellipsis;	
			display:-webkit-box; 	
			-webkit-box-orient:vertical;
			-webkit-line-clamp:2; 
		}
</style>
